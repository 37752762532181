import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#263238', /* #263238 */
                secondary: '#37474F',
                error: '#FF0000',
                /*Rot: #ff3a2d (Classic=Kegeln) alternativ #D23A2D
Blau: #5584c4 (Bowling) */
            },
        },
    },
});
