<template>
    <div>
    <!-- cart btn -->
        <shop-cart-btn class="mt-5" @gotoShoppingCart="gotoShoppingCart"/>
    <!-- empty message -->
        <v-card width="600" class="mx-auto mt-5 mb-0" v-if="eventList.length + passList.length === 0">
            <alert-message :config-prop="{show: true}" >
                {{$t('shopEvent.noEvents')}} <a v-if="organisation.email" :href="'mailto:'+organisation.email">
                {{organisation.email}}</a>.
            </alert-message>
        </v-card>
    <!-- passes first -->
        <div>
            <shop-event v-for="(pass, i) in passList" :key="i" :shop-event="pass"/>
        </div>
    <!-- events second -->
        <div>
            <shop-event v-for="(eventObj, i) in eventList" :key="i" :shop-event="eventObj"/>
        </div>
    <!-- cart btn -->
        <shop-cart-btn class="mt-4 mb-15" @gotoShoppingCart="gotoShoppingCart"/>
    </div>
</template>

<script>
import TimeStamp from "@/utilities/services/timeStamp/timeStamp";
import FirebaseRead from "@/utilities/services/firebase/firebaseRead";
import {ShopEventService} from "@/services/shop/shopEvent";
import ShopCartBtn from "@/components/shop/shopCartBtn";
import ShopEvent from "@/components/shop/shopEvent/shopEvent";
import AlertMessage from "@/utilities/components/displays/alertMessage";

export default {
    name: "shop-event-list",
    components: {AlertMessage, ShopEvent, ShopCartBtn},
    computed: {
        shopArea(){ return this.$route.query.shopArea; },
        organisation(){ return this.$store.state.organisations[this.shopArea] },
        isOthers(){ return this.$route.query.isOthers === 'true'; },
        shopEvents(){ return this.$store.state.shopEvents },
        shopAreasLoaded(){ return this.$store.state.shopAreasLoaded },
        userLicenses(){ return this.$store.state.realtime.userLicenses },
        user(){return this.$store.getters.user},
        masterUser(){ return this.$store.state.realtime.userMaster},
        masterUserSports(){ return this.masterUser?.sports },
    },
    data: () => ({
        eventList: [],
        passList: [],
    }),
    created(){
        this.init();
    },
    methods: {
        init(){
            this.passList = [];
            this.eventList = [];
            this.loadAndSetShopEvents();
            this.passList.sort((a, b) => a.until + a.name > b.until + b.name ? 1 : -1);
            this.eventList.sort((a, b) => a.until + a.name > b.until + b.name ? 1 : -1);
        },
        async loadAndSetShopEvents(){
            if ( !this.userLicenses ) return
        // events already loaded less than 15mins ago
            if ( !this.shopArea || ( this.shopAreasLoaded[this.shopArea] &&
                TimeStamp.getGMTTimestamp() - this.shopAreasLoaded[this.shopArea] < 60*1000*15) ) {
                this.setEventListsFromStore();
                return
            }
        // load events new
            const eventList = await FirebaseRead.readDocListWithTwoWhere('events',
                {field: 'shop.searchKey', operator: '==', value: this.shopArea},
                {field: 'shop.until', operator: '>=', value: TimeStamp.getTodaysDateString()})
            if ( !this.shopEvents ) this.$store.state.shopEvents = {}
            eventList.forEach( (shopEvent) => {
                this.$store.state.shopEvents[shopEvent.id] = shopEvent
                this.addShopEventToListIfPurchaseable(shopEvent)
            });
            this.shopAreasLoaded[this.shopArea] = TimeStamp.getGMTTimestamp();
        },
        setEventListsFromStore(){
            const todaysDateString = TimeStamp.getTodaysDateString()
            Object.keys(this.shopEvents).forEach( (shopEventId) => {
                const shopEvent = this.shopEvents[shopEventId];
                if ( shopEvent.shop.searchKey === this.shopArea &&
                    todaysDateString <= shopEvent.shop.until) {
                    this.addShopEventToListIfPurchaseable(shopEvent)
                }
            });
        },
        async addShopEventToListIfPurchaseable(shopEvent){
            const todaysDateString = TimeStamp.getTodaysDateString()
            if( (shopEvent.shop.from !== undefined && shopEvent.shop.from > todaysDateString) ||
                !shopEvent.shop.display || shopEvent.status === 'pending' ) return
            const shopEventObj = new ShopEventService(shopEvent)
            await shopEventObj.init();
            const isFullyPurchased = shopEventObj.isFullyPurchased()
        // check if userGroup required and user is in userGroup
            if ( shopEvent.requiredUserGroups?.length > 0 ) {
                if ( !this.user ) return
                if ( !this.$store.state.myUserGroups ) {
                    const myUserGroups = await FirebaseRead.readSingleDoc(
                        'user_userGroups', this.user.uid)
                    if ( !myUserGroups) return
                    this.$store.state.myUserGroups = myUserGroups.userGroups
                }
                let userGroupFoundInUser = false
                for ( let i = 0; i < shopEvent.requiredUserGroups.length; i++ ) {
                    if ( this.$store.state.myUserGroups[shopEvent.requiredUserGroups[i]] ) {
                        userGroupFoundInUser = true; break;
                    }
                }
                if ( !userGroupFoundInUser ) return
            }
        // add
            if ( shopEvent.displayInAdminCollection === 'PASSES' && !isFullyPurchased ) {
                this.passList.push(shopEventObj)
            } else if ( shopEvent.displayInAdminCollection !== 'PASSES' && this.eventSportFitsUserSport(shopEventObj.data) ) {
                shopEventObj.isFullyPurchasedStatus = isFullyPurchased
                this.eventList.push(shopEventObj)
            }
        },
        eventSportFitsUserSport(shopEventData){
            if ( !this.masterUserSports || this.masterUserSports.all || this.masterUserSports.none ||
                 !shopEventData?.sports || shopEventData.sports.length === 0 ) return true
            if ( this.isOthers ) {
                return shopEventData.sports?.length === 0 ||
                    !shopEventData.sports.some(sport => Object.keys(this.masterUserSports).includes(sport))
            } else {
                return shopEventData.sports.some(sport => Object.keys(this.masterUserSports).includes(sport))
            }
        },
        gotoShoppingCart(){
            this.$emit('gotoShoppingCart');
        }
    },
    watch: {
        shopArea(to){
            if ( to ) { this.init() }
        },
        userLicenses(to){
            if ( to ) { this.init() }
        },
        user(to){
            if ( to ) { this.init() }
        },
    }
}
</script>

<style scoped>

</style>